<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <c-budget-forms-data-transfer
                    v-if="!isGkkpMode && !(progress < 100) && variantAttribute"
                    :header="header"
                    :lng="lng"
                    :getDataTransferText="getDataTransferText"
                    :getErrText="getErrText"
                    :makeToast="makeToast"
                    @reloadTable="loadDatas"
                />
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button :style="{ 'minWidth': '110px' }" variant="primary" @click="addItem" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</b-button>
                    <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute">{{ getCommonText('save') }}</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                no-border-collapse
                sticky-header="true"
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering table-primary">8</td>
                    <td class="td-numbering table-info">9</td>
                    <td class="td-numbering table-primary">10</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItemWithAttachedFiles(`${selectAll ? getDecText('del_all_recs') : getDecText('del_selected_recs')}`)"/>
                    </div>
                </template>
                <template #cell(action)="data">
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="data.item.itemToDelete"
                            @input="e => { if (!e) selectAll = false; }"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #cell(code_cabinet)="data">
                    <div
                        class="column-width-100"
                        :class="{ 'error': data.item.checkForDuplicates }">
                        <b-form-select v-if="variantAttribute"
                                       size="sm"
                                       class="w-200px"
                                       v-model="data.item.code_cabinet"
                                       :text-field="'name_' + lng"
                                       value-field="code"
                                       :options="filteredCabinets(data.item)"
                                       @change="checkDoubles(data.item, 'cabinet'), cabinetChanged(data.item)"
                        >
                        </b-form-select>
                        <div v-else>{{ dictCabinet.find(el => el.code === data.value)['name_' + lng] }}</div>
                        <template v-if="data.item.checkForDuplicates">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                <p>{{ getCommonText("exist") }}</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template #cell(code_furniture)="data">
                    <b-form-select v-if="variantAttribute"
                                   size="sm"
                                   class="w-200px"
                                   v-model="data.item.code_furniture"
                                   :text-field="'name_' + lng"
                                   value-field="code_furniture"
                                   :options="data.item.dictFurniture"
                                   @change="checkDoubles(data.item, 'furniture')"
                    >
                    </b-form-select>
                    <div v-else>{{ data.item.dictFurniture.find(el => el.code_furniture === data.value)['name_' + lng] }}</div>
                </template>
                <template #cell(amount)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.amount"
                                  @change="v => data.item.amount = v"
                                  :disabled="!data.item.code_cabinet || !data.item.code_furniture"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'amount', data.item.amount, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(made_year)="data">
                    <div v-if="variantAttribute" :class="{ 'error': (data.item.made_year.toString().length !== 4 || parseInt(data.item.made_year) > currYear)}">
                        <b-form-input class="text-right"
                                      :value="data.item.made_year"
                                      @change="v => data.item.made_year = v"
                                      :disabled="isFieldDisabled(data.item)"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^[0-9]+$')"  
                                      @blur="inputFixed(data.item, 'made_year', data.item.made_year, 0)">
                        </b-form-input>
                        <i class="icon icon-danger" v-if="(data.item.made_year.toString().length !== 4 && data.item.made_year != 0)"></i>
                        <div class="pop-up" v-if="(data.item.made_year.toString().length !== 4 && data.item.made_year != 0)">
                            <p class="red-text">{{ getCommonText("not_valid") }}</p>
                            <p>{{ getCommonText("four_symbols") }}</p>
                        </div>
                        <i class="icon icon-danger" v-if="((data.item.made_year.toString().length == 4 && parseInt(data.item.made_year) > currYear))"></i>
                        <div class="pop-up" v-if="(data.item.made_year.toString().length == 4 && parseInt(data.item.made_year) > currYear)">
                            <p class="red-text">{{ getCommonText("not_valid") }}</p>
                            <p>{{ getFormText("made_year_not_valid") }}</p>
                        </div>
                    </div>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(wear)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.wear"
                                  @change="v => data.item.wear = v"
                                  :disabled="!data.item.code_cabinet || !data.item.code_furniture || data.item.amount <= 0"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @onfocus="clearThis('wear', value, data.item)"
                                  @blur="inputFixed(data.item, 'wear', data.item.wear, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(plan)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.plan"
                                  @change="v => data.item.plan = v"
                                  :disabled="!data.item.code_cabinet || !data.item.code_furniture"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixedVldtn(data.item, 'plan', data.item.plan, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.plan === null || data.item.plan === '' || data.item.plan <= 0)">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(cost)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.cost"
                                  @change="v => data.item.cost = v"
                                  :disabled="!data.item.code_cabinet || !data.item.code_furniture"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'cost', data.item.cost, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.cost === null || data.item.cost === '' || data.item.cost <= 0)">{{ getCommonText("positive") }}</p>
                </template>

                <template #cell()="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onFilesClick(data.item)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_rec')" 
                            class="icon icon-clear table-remove" 
                            v-if="variantAttribute" 
                            @click="deleteItemWithAttachedFiles(`${getCommonText('del_rec')}?`, data.item, data.index)"
                        ></i>
                    </div>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="9">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="addItem"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      @onFilePreview="onFilePreview"
                      ref="fileUpdown"
        ></files-updown>
        <modal-files-management-nodecode
            ref="modalFilesManagement"
            :variant-attribute="variantAttribute"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            @fileUpload="fileUpload"
            @toggleIsAdd="toggleIsAdd($event)"
            />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import store from "../../services/store";
import ModalFilesManagementNodecode from './components/modal-files-management-nodecode.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import CBudgetFormsDataTransfer from './components/budget-forms-data-transfer.vue';

export default {
    name: 'Form02_414',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        FormsDownloadReprt, 
        BreadcrumbsFilter, 
        ModalFilesManagementNodecode, 
        CBudgetFormsCopyData,
        CBudgetFormsDataTransfer
    },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '02-414',
                name_ru: 'Расчет расходов государственных органов на приобретение офисной мебели',
                name_kk: 'Мемлекеттiк мекеменiң кеңсе жиһаздын сатып алуға арналған шығыстарын есептеу'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dictCabinet: null,
            dictFurniture: null,
            cabinetFurniture: [],
            files: null,
            load: false,
            isLoad: false,
            openDisabled: false,
            usrId: null,
            budgetLevel: [],
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
        };
    },

    watch: {
        header(header) {
            if (header !== null) {
                this.loadFilterBudgetLevel();
            }
        }
    },

    async mounted() {
        this.usrId = await this.getUsrId();
        await this.loadDictCabinet();
        await this.loadDictFurniture();
        await this.loadCabinetFurniture();
    },


    methods: {
        async getUsrId() {
            if (store.state.user.sub === undefined) {
                return null;
            }
            return store.state.user.sub;
        },

        addItem() {
            if (this.header !== null) {
                const item = { id: this.newRowStartId };
                this.newRowStartId--;
                this.itemUpdate(item);

                Object.defineProperty(item, 'total', {
                    get: function () {
                        const total = (item.cost * item.plan) / 1000;
                        return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                    }
                });
                this.budgetForm.push(item);
            }
            this.checkDoubles();
        },

        checkDoubles(value, str) {
            const firstEntryItm = {};
            this.budgetForm.forEach((itm, idx) => {
                const cabinetCode = itm.code_cabinet ? itm.code_cabinet : 'no code';   
                const furnitureCode = itm.code_furniture ? itm.code_furniture : 'no code'; 
                if (!firstEntryItm.hasOwnProperty(cabinetCode)) {
                    firstEntryItm[cabinetCode] = {};
                    firstEntryItm[cabinetCode][furnitureCode] = idx;
                    itm.checkForDuplicates = false;
                } else {
                    if (!firstEntryItm[cabinetCode].hasOwnProperty(furnitureCode)) {
                        firstEntryItm[cabinetCode][furnitureCode] = idx;
                        itm.checkForDuplicates = false;
                    } else {                        
                        itm.checkForDuplicates = true;
                        const firsDoubleIdx = firstEntryItm[cabinetCode][furnitureCode];
                        this.budgetForm[firsDoubleIdx].checkForDuplicates = true;
                    };
                };
            });
            if (value !== undefined) {
                if (str === 'cabinet') {
                    this.changeCabinet(value);
                } else if (str === 'furniture') {
                    this.changeFurniture(value);

                }
            }
        },

        afterDeleted() {
            this.checkDoubles();
        },

        changeCabinet(item) {
            this.$set(item, 'dictFurniture',
                this.cabinetFurniture.filter(row => row.code_cabinet === item.code_cabinet));
        },

        changeFurniture(item) {
            const values = this.cabinetFurniture.filter(row =>
                row.code_cabinet === item.code_cabinet && row.code_furniture === item.code_furniture);
            if (values !== undefined && values.length > 0) {
                this.$set(item, 'standard', values[0].amount);
            }
        },

        isFieldDisabled(item) {
            return !item.code_cabinet || !item.code_furniture || item.amount <= 0;
        },

        inputFixed(item, field, value, digit) {
            if (!Number(value)) value = null;
            if (value === '' || value === null || value === '0') {
                if (value !== '0') this.$set(item, field, 0)
                else this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
                if ((field === 'amount') 
                    && ((!item.amount) 
                    || (item.item.amount <= 0))) {
                    this.$set(item, 'wear', 0);
                    this.$set(item, 'made_year', 0);
                }
                if (field === 'wear' || field === 'made_year') this.checkForDoubles(item);
                return;
            }
            this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
            if (field === 'wear' && value > 100) {
                this.$set(item, field, 100);
            }
            
            if (field === 'made_year') {
                const today = new Date(); 
                const currYear = today.getFullYear()
                if (item.made_year < currYear - 200) {
                    this.$set(item, field, currYear - 200);
                }
                if (item.made_year > currYear) {
                    this.$set(item, field, currYear);
                }
            }
            this.checkForDoubles(item); 
        },

        cabinetChanged(item) {
            this.$set(item, 'code_furniture', null);
        },

        async itemUpdate(item) {
            this.$set(item, 'code_cabinet', null);
            this.$set(item, 'code_furniture', null);
            this.$set(item, 'standard', 0);
            this.$set(item, 'amount', 0);
            this.$set(item, 'made_year', 0);
            this.$set(item, 'wear', 0);
            this.$set(item, 'plan', 0);
            this.$set(item, 'itemToDelete', false);
            this.$set(item, 'cost', 0);

            // этот атрибут чтобы найти схожий запись по наименованию
            this.$set(item, 'checkForDuplicates', false);

            this.$set(item, 'files', 0);

            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} itemUpdate`, error.toString());
            }
            this.load = false;
        },

        getFiles(data) {
            this.files = data;
        },

        getItem(code, list) {
            for (const item of list) {
                if (item.code === code.toString()) {
                    return item;
                }
            }
            return null;
        }, // возвращает объект по коду с заданного списка

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const charCode = event.keyCode;
            const key = String.fromCharCode(charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadBudgetLevel() {
            this.budgetLevel.splice(0);
            if (this.usrId === null) { return; }
            let result = [];
            try {
                result = await fetch(encodeURI('/api-py/get-user-budget-level/' + this.usrId)).then(response => response.json());
            } catch (error) {
                this.makeToast('danger', this.getFormText('budg_lvl_err'), error.toString());
                return;
            }

            for (const el of result) {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const indx = el.budget_level.lastIndexOf('_');
                if (indx >= 0) {
                    this.budgetLevel.push(parseInt(el.budget_level.substr(indx + 1)));
                }
            }
            if (this.budgetLevel.length === 0) {
                this.makeToast('warning', this.getCommonText('attention'), this.getFormText('no_budg_lvl'));
            }
        },

        async loadFilterBudgetLevel() {
            this.budgetLevel.splice(0);
            if (this.header === null) { return; }
            let result = [];
            try {
                result = await fetch(encodeURI('/api-py/get-budget-level-from-filter/' + JSON.stringify(this.header))).then(response => response.json());
            } catch (error) {
                this.makeToast('danger', this.getFormText('budg_lvl_err'), error.toString());
                return;
            }

            for (const el of result) {
                const indx = el.budget_level.lastIndexOf('_');
                if (indx >= 0) {
                    this.budgetLevel.push(parseInt(el.budget_level.substr(indx + 1)));
                }
            }
            if (this.budgetLevel.length === 0) {
                this.makeToast('warning', this.getCommonText('attention'), this.getFormText('no_budg_lvl'));
            }
        },
        
        async loadCabinetFurniture(){
            try {
                const response = await fetch('/api-py/dictionary/cabinet_furniture/');
                const items = await response.json();

                for (const row of items) {
                    const furniture = this.getItem(row.code_furniture, this.dictFurniture);
                    if (furniture !== null) {
                        this.$set(row, 'name_ru', furniture.name_ru);
                        this.$set(row, 'name_kk', furniture.name_kk);
                    }
                }
                this.cabinetFurniture = items;
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadCabinetFurniture`, error.toString());
            }
        },

        async loadDatas() {
            this.load = true;
            await this.budgetForm.splice(0);
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                values = await response.json();
                // в этот массив записывается все наименование записей, потом по этому массиву идет проверка
                // если в него есть массив то атрибут checkForDuplicates равен true
                const listCheckDuplicates = [];

                await values.forEach(val => {
                    const item = val;
                    item['checkForDuplicates'] = !!listCheckDuplicates.find(el => el.code_cabinet === item.code_cabinet && el.code_furniture === item.code_furniture)

                    listCheckDuplicates.push({
                        'code_cabinet': item.code_cabinet,
                        'code_furniture': item.code_furniture
                    });
                    item.files = val.files;
                    item.row_files = val.row_files;
                    item.itemToDelete = false;

                    this.changeCabinet(item);
                    this.changeFurniture(item);
                    Object.defineProperty(item, 'total', {
                        get: function () {
                            const total = (item.cost * item.plan) / 1000;
                            return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                        }
                    });

                    this.budgetForm.push(item);
                });
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas`, error.toString());
            }
            this.load = false;
        },

        async loadDictCabinet() {
            try {
                const response = await fetch('/api-py/dictionary/cabinets/');
                this.dictCabinet = await response.json();
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDictCabinet`, error.toString());
            }
        },

        filteredCabinets(item) {
            if (!this.dictCabinet) {
                return [];
            }
            let cabinets = this.dictCabinet.filter(cab => this.budgetLevel.includes(cab.budget_level_id));
            const savedCabinet = this.dictCabinet.find(cab => cab.code === item.code_cabinet);
            if (savedCabinet && !cabinets.some(cab => cab.code === savedCabinet.code)) {
                cabinets.unshift({ ...savedCabinet, disabled: true });
            } else {
                cabinets = cabinets.map(cab => ({
                    ...cab,
                    disabled: cab.code === item.code_cabinet
                }));
            }
            return cabinets;
        },

        getUnitLockName(item) {
            if (this.lng === 'kk') return item.name_kk;
            return item.name_ru;
        },

        async loadDictFurniture() {
            try {
                const response = await fetch('/api-py/dictionary/furniture/');
                this.dictFurniture = await response.json(); 
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDictFurniture`, error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        prepareForSave() {
            const values = [];
            let error = false;
            for (const row of this.budgetForm) {
                if (this.optionNotSelected(row, this.tableFields, ['code_cabinet', 'code_furniture'])) return;
                else {
                    if (this.isSavingForbidden(row, this.tableFields, ['plan', 'cost'], [])) return;
                }
                if (!((row.code_cabinet.length === 0) || (row.code_furniture.length === 0)
                    || (row.standard.length === 0) || (((row.made_year.toString().length !== 4) || (parseInt(row.made_year) > this.currYear)) && row.made_year != 0))) {
                    const item = Object.assign({}, this.header);
                    this.$set(item, 'id', row.id);
                    this.$set(item, 'code_cabinet', row.code_cabinet);
                    this.$set(item, 'code_furniture', row.code_furniture);
                    this.$set(item, 'standard', parseInt(row.standard));
                    this.$set(item, 'amount', parseInt(row.amount));
                    this.$set(item, 'made_year', parseInt(row.made_year));
                    this.$set(item, 'wear', parseFloat(row.wear));
                    this.$set(item, 'plan', parseInt(row.plan));
                    this.$set(item, 'cost', parseFloat(row.cost));
                    this.$set(item, 'row_files', row.row_files);
                    this.$set(item, 'total', row.total);
                    values.push(item);
                } else {
                    error = true;
                }
            }
            if (error) {
                this.onFieldsValidationFailed();
                return;
            }
            if (this.budgetForm.find(item => item.checkForDuplicates === true)) {
                this.makeToast('danger', this.getErrText('warning'), `${this.getErrText('not_valid')}. ${this.getErrText('not_saved')}!`);
            }
            else {
                if (values.length && this.variantAttribute) {
                    this.save(values, error);
                }
                else {
                    this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
                }
            }
        }, // подготовка к сохранению

        async save(values, error) {
            this.isLoad = true;
            try {
                const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp/' : '/api-py/save-brform' + this.form.code + '/'
                const totalToSave = this.totalCalculation(values, 'total')
                this.$set(this.header, 'value', totalToSave);
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных
    },

    computed: {
        total() {
            return this.totalCalculation(this.budgetForm, 'total');
        },

        currYear() {
            const currentYear = new Date().getFullYear();
            return currentYear;
        },

        getFormText() {
            return this.setFormText('form_02_414.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'code_cabinet',
                        label: this.getFormText('pos_name')
                    },
                    {
                        key: 'code_furniture',
                        label: this.getFormText('furniture_name')
                    },
                    {
                        key: 'standard',
                        label: this.getFormText('furniture_norm')
                    },
                    {
                        key: 'amount',
                        label: this.getFormText('fact_furniture_count')
                    },
                    {
                        key: 'made_year',
                        label: this.getFormText('release_year')
                    },
                    {
                        key: 'wear',
                        label: this.getFormText('norm_wearout')
                    },
                    {
                        key: 'plan',
                        label: this.getFormText('planned_furniture_count')
                    },
                    {
                        key: 'cost',
                        label: this.getFormText('price_per_unit')
                    },
                    {
                        key: 'total',
                        label: this.getFormText('total_cost')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        },
    }
};
</script>
<style scoped>
    .goods-form-val-msg {
        font-size: 0.5rem;
        font-style: italic;
        color: red;
    }
    .filter-actions-flex {
        display: flex;
    }
</style>